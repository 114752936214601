.app-body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.page {
    width: 800px;
    background-color: #fff;
    border: 3px solid black;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #d7e5f2; /* Background color from the image */

}

.header-title, .header-subtitle {
    text-align: center;
}

.subscription-table {
    margin-left: -21px;
    margin-top: -1px;
    width: 105.5%;
    border-collapse: collapse;
    margin-bottom: -16px;
}

.subscription-table th, .subscription-table td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}

.subscription-table th {
    background-color: #f2f2f2;
    text-align: center;

}

.subscription-notes {
    list-style: none; /* Remove default list styling */
    padding: 0;       /* Remove default padding */
    margin: 0;        /* Remove default margin */
    counter-reset: li; /* Reset the counter */
    line-height: 20px;
}

.subscription-notes li {
    position: relative;      /* Position relative for absolute child positioning */
    padding: 10px 15px 10px 40px; /* Adjust padding for the numbers */
    counter-increment: li;    /* Increment the counter */
}

.subscription-notes li::before {
    content: counter(li) ".";  /* Display the counter */
    position: absolute;        /* Position absolute for custom numbering */
    left: 0;                   /* Position the numbers to the left */
    top: 50%;                  /* Vertically center */
    transform: translateY(-50%);
    font-weight: bold;         /* Make the numbers bold */
}

.subscription-notes li::after {
    content: "";
    position: absolute;
    left: 34px;
    top: 0;
    bottom: 0;
    height: 89px;
    border-left: 1px solid #000;
    margin-top: -23px;
}

.footer-annual-sub {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
    width: 100%;
    background-color: #d7e5f2; /* Background color from the image */
    margin-top: -20px;

}

.footer-left-annual-sub,
.footer-right-annual-sub {
    flex: 1;
}

.footer-left-annual-sub p,
.footer-right-annual-sub p {
    margin: 0;
}

.footer-left-annual-sub {
    display: flex;
    flex-direction: column;
    margin-left: -14px;
    line-height: 41px;
}

.footer-right-annual-sub {
    text-align: right;
    margin-right: 56px;
    margin-top: 90px;
}

.subscription-table th {
    background-color: #d7e5f2; /* Background color from the image */

}

.cut-hr {
    width: 105.5%;
    margin-left: -22px;
    border: 1px solid black;
    margin-top: 14px;

}

.member-info table {
    width: 105.8%;
    margin-left: -22px;
    margin-top: -25px;
    border-collapse: collapse;
}

.member-info td {
    padding: 8px;
    border: 3px solid black;
}

.margin-t5{
    margin-top: 5px;
}