
.smaller-table {
    width: 60%;
    font-size: 15px;
}

.smaller-table th{
    padding: 4px; /* Adjust padding as needed */
    line-height: 1; /* Adjust line height as needed */
    background-color: yellow;
    color: green;
    width: 20%;
}

.smaller-table td {
    padding: 4px; /* Adjust padding as needed */
    line-height: 1; /* Adjust line height as needed */
}

.info-container h6 {
    margin: 10px 0;
}

.form-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}
.membership-plan-group {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}
.membership-plan-group label {
    margin-right: 10px;
    flex-shrink: 0;
}
.membership-plan-group input {
    flex-grow: 1;
    padding: 8px;
    box-sizing: border-box;
}
.membership-plan-group-inline {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
}
.membership-plan-group-inline .membership-plan-group {
    flex: 1;
}
.full-width {
    flex: 1 1 100%;
}

.planTitle {
    line-height: 2px;
}
.boldText {
    font-weight: bold;
}
 
.form-control.important-height {
    height: 50px !important;
}
