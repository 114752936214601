
/* FORM ONBOARDING
======================================================================================== */
.form-onboarding .form-control {
    height: 20px !important;
}

.form-onboarding .form-control2 {
    height: 36px !important;
}

.form-onboarding .form-group {
    margin-bottom: 32px !important;
    font-size: 18px;
}

.form-onboarding {
    padding: 70px;
    padding-top: 0px;
    padding-bottom: 10px;
}

.edit-icon {
    position: absolute;
    top: 50px;
    right: 16px;
    cursor: pointer;
}

.avatar__cancel {
    position: absolute;
    top: 210px;
    right: 16px;
    cursor: pointer;
}

.avatar__cancel_signature {
    position: absolute;
    top: 268px;
    right: 16px;
    cursor: pointer;
}

legend {
    margin-bottom: 40px;
    text-align: center;
    font-size: 16.5px;
    color: #101010;
}
.add-text {
    display: none;
}

/* Show the "Add" text on hover of the pencil icon */
.edit-icon:hover .add-text {
    display: inline;
}

.avatar__cancel:hover .add-text {
    display: inline;
}

.avatar__cancel_signature:hover .add-text {
    display: inline;
}

.upload-button {
    background-image: url('https://img.icons8.com/?size=100&id=83225&format=png&color=000000');
    background-size: 20px;  
    background-repeat: no-repeat;
    background-position: left center;
    background-position: left 10px center;  
    padding-left: 30px;
  }
  
.footer {
  background-color: #f5f5f5;
  padding: 1rem 0;
  text-align: center;
  color: #333;
}

.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  max-width: 1000px;
  margin: 0 auto;
}

.footer nav {
  margin: 0;
  padding: 0;
  list-style: none;  
}

.footer nav li {
  display: inline-block;
  margin: 0 1rem; 
}

.footer nav a {
  text-decoration: none;
  color: inherit;
}

.footer nav a:hover {
  color: #999;
}

.simple-footer {
    position: relative;  
}
  
 
.page-number {
    position: absolute;  
    right: 0; 
    bottom: 0; 
    margin-bottom: 20px; 
    margin-right: 20px;
}
  
.doc__cancel {
    position: absolute;
    top: 90px;
    right: 38px;
    cursor: pointer;
}

.doc__cancel:hover .add-text {
    display: inline;
}

.edit-doc {
    position: absolute;
    top: -8px;
    right: 38px;
    cursor: pointer;
}

.edit-doc:hover .add-text {
    display: inline;
}

.invalid-feedback {
    color: red;
}

.form-onboarding  .height_address {
    height: 30px !important;
}


/* ------------button hover ------------- */
/* styles.css */

.tooltip-wrapper {
    position: relative;
    display: inline-block;
}

.tooltip-inner {
    max-width: 250px;
    padding: 1px 1px;
    color: black;
    text-align: center;
    background-color: white;
    border-radius: 12px;
    border: 0.1px solid #80808054;
}
.tooltip-container {
    display: none;
    position: absolute;
    left: -170px; /* Adjust this value based on your layout */
    transform: translateY(-120%);
    background-color: #fff;
    color: #000;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    width: 250px;
    z-index: 1000;
}

.tooltip-wrapper:hover .tooltip-container {
    display: block;
}

.tooltip-inner {
    font-size: 13px;
}

.tooltip-inner p {
    margin: 5px 0;
}

.tooltip-inner ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.tooltip-inner ul li {
    margin-left: 20px;
}

/* ------------Disable Click------------- */

.disabled-overlay {
    pointer-events: none;
    z-index: 1000; /* Ensure it covers all other elements */
}

.inputMemberForm .form-control {
    color: #000000eb;
    font-weight: 500;
}

.inputMemberForm label {
    color: #615959;
}

.onBoardUser .form-control{
    font-size: 22px;
}

.onBoardUser legend{
    font-size: 22px;
}

.onBoardUser .panel-title{
    font-size: 19px;
}